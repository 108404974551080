<template>
  <div>
    <app-header />

    <main v-if="!loading">
      <div
        class="
          uk-section uk-padding-remove-vertical uk-margin-remove-vertical
          in-equity-breadcrumb
        "
      >
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <ul class="uk-breadcrumb">
                <li>
                  <router-link :to="{ name: 'public.home' }">Home</router-link>
                </li>
                <li><span>Community</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section
          in-equity-11
          uk-background-contain uk-background-top-right
        "
        :style="`background-image: url('${slider.background}')`"
      >
        <div
          class="uk-container uk-margin-top uk-margin-bottom"
          v-if="!loading_slider"
        >
          <div class="uk-width-3-4@m">
            <div
              class="
                uk-grid-medium
                uk-grid-match
                uk-child-width-1-3@m
                uk-child-width-1-2@s
                uk-grid
              "
            >
              <div class="uk-width-1-1 uk-first-column">
                <h1 class="uk-margin-small-bottom">
                  <textarea
                    v-model="slider.title"
                    placeholder="Title"
                    rows="3"
                    style="font-size: 40px"
                    class="uk-textarea"
                  ></textarea>
                </h1>
                <p class="uk-text- uk-margin-remove-top">
                  <textarea
                    v-model="slider.tagline"
                    placeholder="Tagline"
                    rows="3"
                    class="uk-textarea"
                  ></textarea>
                </p>
                <p class="uk-margin-remove">
                  <label>Change Background</label>
                  <input type="file" ref="slider_background" class="uk-input" />
                </p>
              </div>
              <div
                class="uk-grid-margin uk-first-column"
                v-for="(item, i) in slider.features"
                :key="`slider-featured-item-${i}`"
              >
                <div
                  class="
                    uk-card
                    uk-card-default
                    uk-card-body
                    uk-border-rounded
                    uk-box-shadow-medium
                  "
                >
                  <div class="uk-flex uk-flex-middle">
                    <div class="uk-margin-right">
                      <a
                        href="#"
                        style="font-size: 12px"
                        @click.prevent="openImageSelector(i)"
                        >Change</a
                      >
                      <input
                        type="file"
                        :id="`slider_featured_item_${i}`"
                        ref="slider_featured"
                        @change="changeFeaturedIcon(i)"
                        style="display: none"
                      />
                      <template v-if="!changing_icon">
                        <img
                          :src="item.icon"
                          alt="icon-1"
                          width="35"
                          height="42"
                          v-if="changed_icons[i] == false"
                          style="width: 50px"
                        />
                        <image-preview
                          v-if="changed_icons[i] != false"
                          :image="changed_icons[i]"
                          style="width: 50px"
                        />
                      </template>
                    </div>
                    <div>
                      <h5 class="uk-margin-small-bottom">
                        <input
                          type="text"
                          v-model="item.title"
                          placeholder="Title"
                          class="uk-input"
                        />
                      </h5>
                      <p class="uk-text-small uk-margin-remove">
                        <textarea
                          v-model="item.content"
                          placeholder="Content"
                          class="uk-textarea"
                          rows="5"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-grid-margin uk-first-column">
                <p class="uk-text-small uk-text-muted">
                  <span
                    class="
                      uk-label uk-label-info uk-margin-small-right
                      in-label-small
                    "
                    >Note</span
                  >
                  <textarea
                    placeholder="Note"
                    v-model="slider.note"
                    rows="3"
                    class="uk-textarea"
                  ></textarea>
                </p>
                <p class="uk-margin-remove">
                  <span style="display: block; margin-top: 10px"
                    ><a
                      href="#"
                      @click.prevent="updateSlider"
                      class="uk-button uk-button-primary"
                      >Save
                    </a></span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <loading v-if="loading_slider" />
      </div>

      <div
        class="uk-section uk-padding-remove-vertical uk-margin-medium-bottom"
        style="
          background-image: url('/theme/in-team-background-1.png');
          background-color: #fff;
        "
      >
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <div
                class="
                  uk-card
                  uk-card-default
                  uk-border-rounded
                "
              >
                <div class="uk-card-body">
                  <div class="uk-grid uk-flex uk-flex-center">
                    <div class="uk-width-1-1@m uk-text-center">
                      <template v-if="!loading_about">
                        <editor v-model="about" height="600px" />
                        <div class="uk-margin-top uk-text-left">
                          <a href="#" class="uk-button uk-button-primary" @click.prevent="updateAbout">Save</a>
                        </div>
                      </template>
                      <template v-if="loading_about">
                        <loading />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-section in-equity-3 in-offset-top-20">
        <community-plans :loading="loading" :community="community" />
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-decor-2.svg')"
      >
        <getting-started
          :loading="loading"
          :getting_started="getting_started"
        />
      </div>
    </main>

    <loading v-if="loading" />

    <app-footer :disclaimer="disclaimer" :loading="loading" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import CommunityPlans from "../shared/CommunityPlans";
import GettingStarted from "../shared/GettingStarted";

export default {
  components: {
    AppHeader,
    AppFooter,
    CommunityPlans,
    GettingStarted,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      slider: {},
      loading_slider: false,
      community: {},
      changed_icons: [false, false, false, false],
      changing_icon: false,
      about: "",
      loading_about: false,
      disclaimer: "",
      getting_started: {},
    };
  },

  methods: {
    changeFeaturedIcon(i) {
      this.changed_icons[i] = this.$refs.slider_featured[i].files[0];
      this.changing_icon = true;

      setTimeout(() => {
        this.changing_icon = false;
      }, 10);
    },

    openImageSelector(i) {
      document.getElementById(`slider_featured_item_${i}`).click();
    },

    updateAbout() {
      this.loading_about = true

      let data = {
        about : this.about
      }

      this.$axios
        .post("/api/v1/admin/sitecontent/public-community-about", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.about = response.data.about;
          this.loading_about = false;
        });
    },

    fetch() {
      this.loading = true;

      this.$axios
        .get("/api/v1/sitecontent/public-community")
        .then((response) => {
          this.slider = response.data.slider;
          this.about = response.data.about;
          this.community = response.data.community;
          this.getting_started = response.data.getting_started;
          this.disclaimer = response.data.disclaimer;
          this.loading = false;
        });
    },

    updateSlider() {
      this.loading_slider = true;

      let data = new FormData();
      data.append("title", this.slider.title);
      data.append("tagline", this.slider.tagline);
      data.append("note", this.slider.note);

      if (this.$refs.slider_background.files[0]) {
        data.append("background", this.$refs.slider_background.files[0]);
      }

      this.slider.features.forEach((item, i) => {
        data.append(`features[${i}][title]`, item.title);
        data.append(`features[${i}][content]`, item.content);

        if (this.changed_icons[i]) {
          data.append(`features[${i}][icon]`, this.changed_icons[i]);
        }
      });

      this.$axios
        .post("/api/v1/admin/sitecontent/public-community-slider", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.slider = response.data.slider;
          this.loading_slider = false;
        });
    },
  },
};
</script>
